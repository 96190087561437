:root {

}
.tree {
	display: grid;

}
.li {
  &:first-child{
	& > svg {
	  display: none;
	}
  }
  &:nth-child(n+2) {
	margin: .25rem 0;
	display: inline-flex;
	& > svg {
	  color: #4f4f4f;
	  margin-right: .5rem;
	  width: 1.25rem;
	  height: 1.25rem;
	}
  }
  &:nth-child(2) {
	padding-left: 0;
  }
  &:nth-child(3) {
	padding-left: 1rem;
  }
  &:nth-child(4) {
	padding-left: 3rem;
  }
  &:nth-child(5) {
	padding-left: 4rem;
  }


}
